<template>
  <div
    class="flex w-full h-full justify-around items-center rounded-2xl shadow-md"
    :class="!bgColour ? 'bg-lightGrey' : bgColour"
  >
    <contract-type-icon
      :classIcon="'text-teal'"
      :classNotification="'text-teal right-11'"
      :title="'Accepted'"
      :value="data?.count?.accepted"
    />
    <contract-type-icon
      :classIcon="'text-orange'"
      :classNotification="'text-orange right-10'"
      :title="'Revised'"
      :value="data?.count?.revised"
    />
    <contract-type-icon
      :classIcon="'text-lightGreen'"
      :classNotification="'text-lightGreen right-10'"
      :title="'Pending'"
      :value="data?.count?.pending"
    />
    <contract-type-icon
      :classIcon="'text-red'"
      :classNotification="'text-red right-10'"
      :title="'Declined'"
      :value="data?.count?.declined"
    />
  </div>
</template>
  
  <script>
import contractTypeIcon from "./contractTypeIcon.vue";
export default {
  props: {
    bgColour: {
      type: String,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  components: { contractTypeIcon },
};
</script>
  