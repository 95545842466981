<template>
  <router-link class="h-1/4" :to="{ name: link }">
    <div
      class="
        w-full
        relative
        h-full
        text-darkGrey
        shadow
        hover:shadow-2xl
        rounded-2xl
        transition-all
        duration-500
        cursor-pointer
      "
    >
      <span
        class="
          material-icons
          absolute
          text-5xl
          left-1/2
          top-1/2
          transform
          -translate-y-1/2 -translate-x-1/2
        "
      >
        {{ icon }}
      </span>
    </div>
    <!-- <div
      class="relative w-full h-full text-darkGrey shadow hover:shadow-2xl rounded-2xl transition-all duration-500 cursor-pointer"
    >
      <span
        class="material-icons text-5xl absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"
      >
        {{ icon }}
      </span>
    </div> -->
  </router-link>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
    },
  },
};
</script>

<style scoped>
a.router-link-exact-active div {
  background-color: rgba(0, 164, 153, 1);
  color: white;
}
</style>
