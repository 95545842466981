<template>
  <router-link
    :class="`
      flex
      bg-lightGrey
      text-grey
      w-32
      h-1/3
      justify-center
      items-center
      shadow
      hover:shadow-2xl
      rounded-2xl
      transition-all
      duration-500
      cursor-pointer
      p-3
      ${containerStyles}`
    "
    :to="{ name: link }"
  >
    <div class="text-center">
      <span :class="`material-icons text-${iconSize}`">
        {{ icon }}
      </span>

      <p>{{ title }}</p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      required: false,
      default: "4xl",
    },
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
    },
    containerStyles:{
      type: String,
      required: false,
    }
  },
};
</script>
