<template>
  <!-- <div class="flex">
    <div class="flex">
      <h1 class="text-orange text-2xl font-bold pt-1">Human Resources</h1>
    </div>
  </div> -->
  <div class="flex items-center h-full pb-2" v-if="checkRoute">
    <div class="flex flex-col h-full w-48">
      <div class="h-1/2">
        <item-card-dashboard :icon="'group_add'" :link="'hq-recruitment'">
        </item-card-dashboard>
      </div>
      <div class="h-1/2">
        <item-card-dashboard :icon="'task'" :link="'hq-humanResources'">
        </item-card-dashboard>
      </div>
    </div>
    <div class="w-full h-full">
      <div class="w-full h-1/2 m-1">
        <div class="flex w-full h-full space-x-2 pl-3">
          <div
            class="
              flex-shrink
              p-1
              text-darkGrey
              flex flex-col
              justify-around
              space-y-1
            "
          >
            <base-tile
              iconSize="4xl"
              :icon="'group_add'"
              :title="'Open Hire Applications'"
              :link="'hq-recruitment'"
            />

            <base-tile
              iconSize="4xl"
              :icon="'headset_mic'"
              :title="'Interviews'"
              :link="'hq-interviews'"
            />

            <base-tile
              iconSize="4xl"
              :icon="'task'"
              :title="'Candidate Offers'"
              :link="'hq-candidate-offers'"
            />
          </div>
          <div
            v-if="!isLoading"
            class="flex-grow p-1 w-1/2 flex flex-col justify-around space-y-1"
          >
            <contract-type-horizontal :data="getNewApplicationsHiringRequest" />
            <contract-type-horizontal :data="getAllInterviews" />
            <contract-type-horizontal-offers :data="getAllOffers" />
          </div>
          <div
            class="flex-grow p-1 w-1/2 flex justify-center items-center pr-2"
          >
            <div class="bg-lightGrey w-full h-full rounded-2xl">
              <div class="h-full">
                <vueper-slides fixed-height="100%" class="no-shadow">
                  <vueper-slide
                    v-for="(slide, i) in slides"
                    :key="i"
                    :title="slide.title"
                    :content="slide.content"
                  />
                </vueper-slides>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center w-full h-1/2"></div>
    </div>
  </div>
  <router-view></router-view>
</template>
<script>
import itemCardDashboard from "@/components/ui/baseComponents/itemCardDashboard.vue";
import baseTile from "@/components/ui/baseComponents/baseTile.vue";
import contractTypeHorizontal from "@/components/ui/baseComponents/contractTypeHorizontal.vue";
import contractTypeHorizontalOffers from "@/components/ui/baseComponents/contractTypeHorizontalOffers.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: {
    VueperSlides,
    VueperSlide,
    itemCardDashboard,
    baseTile,
    contractTypeHorizontal,
    contractTypeHorizontalOffers,
  },
  created() {
    this.fetchAllHiringRequests(null);
    this.fetchAllInterviews();
    this.fetchAllOffers();
    // this.fetchAllHiringRequests("approved");
    // this.fetchAllHiringRequests("declined");
    // this.fetchAllHiringRequests("escalated");
  },
  data() {
    return {
      isLoading: false,
      slides: [
        {
          title: "Slide #1",
          content: "Slide content.",
        },
        {
          title: "Slide #2",
          content: "Slide content.",
        },
      ],
    };
  },
  methods: {
    async fetchAllHiringRequests(status) {
      try {
        this.isLoading = true;
        await this.$store.dispatch("HQnewHire/fetchAllHiringRequests", [
          {
            status,
            page: null,
          },
        ]);
        this.isLoading = false;
        console.log(this.isLoading);
      } catch (error) {
        console.log(error);
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchAllInterviews() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("HQnewHire/fetchAllInterviews");
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchAllOffers() {
      try {
        this.isLoading = true;
        await this.$store.dispatch("HQnewHire/fetchAllOffers");
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  computed: {
    checkRoute() {
      if (
        this.$route.name === "hq-humanResources" ||
        this.$route.name === "hq-new-hire" ||
        this.$route.name === "hq-staff-training" ||
        this.$route.name === "hq-staff-induction" ||
        this.$route.name === "hq-appraisals"
      ) {
        return true;
      }
      return false;
    },
    getNewApplicationsHiringRequest() {
      return this.$store.getters["HQnewHire/getNewApplicationsHiringRequest"];
    },
    getAllInterviews() {
      return this.$store.getters["HQnewHire/getAllInterviews"];
    },
    getAllOffers() {
      return this.$store.getters["HQnewHire/getAllOffers"];
    },
  },
};
</script>
