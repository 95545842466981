<template>
  <div class="text-center text-grey w-full">
    <p class="font-semibold text-grey text-md mx-3" :class="classTitle">
      {{ title }}
    </p>
    <div class="relative w-full">
      <span class="material-icons text-6xl mx-3" :class="classIcon">
        person
      </span>
      <span
        class="w-6 h-6 rounded-full text-md absolute right-7 top-9 leading bg-white shadow-md"
        :class="classNotification"
      >
        {{ value }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classTitle: {
      type: String,
    },
    classIcon: {
      type: String,
    },
    classNotification: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    textSizeIcon: {
      type: String,
      default: "text-6xl",
    },
  },
};
</script>
